import React from 'react';
import { Button, Table } from 'semantic-ui-react';

const ServiceListItem = (props) => {
  const { servicio, onEdit, onDelete } = props;
  return (
    <Table.Row key={servicio.id}>
      <Table.Cell textAlign="right">{servicio.cantidad}</Table.Cell>
      <Table.Cell>{servicio.servicio.nombre}</Table.Cell>
      <Table.Cell>{servicio.descripcion}</Table.Cell>
      <Table.Cell>{servicio.hora_inicio}</Table.Cell>
      <Table.Cell>{servicio.hora_fin}</Table.Cell>
      <Table.Cell textAlign="right">
        <Button
          onClick={() => onEdit(servicio.id === undefined ? servicio.key : servicio.id)}
          basic
        >
          Editar
        </Button>
        <Button
          icon="delete"
          onClick={() => onDelete(servicio.id === undefined ? servicio.key : servicio.id)}
          color="red"
          basic
        >
          Eliminar
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

export default ServiceListItem;
