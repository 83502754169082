/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Icon,
  Header,
  Button,
  Loader,
  Table,
  Pagination,
  Dimmer,
  Confirm,
  ButtonGroup,
  Label,
  Popup,
  Dropdown,
  GridRow,
  GridColumn,
  Grid,
  Form,
} from 'semantic-ui-react';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { format } from 'date-fns';
import { useParams, useSearchParams } from 'react-router-dom';
import { getVauchers, firmarVaucher, updateVaucher } from '../../hooks/apiManager';
import Error from '../Error';
import Filters from './Filters';

const DEFAULT_PAGINATION = { current_page: 1, total_pages: 0 };
const PAGE_SIZES = [
  { key: 5, value: '5', text: '5' },
  { key: 10, value: '10', text: '10' },
  { key: 20, value: '20', text: '20' },
  { key: 50, value: '50', text: '50' },
];

const VauchersIndex = () => {
  const [data, setData] = useState({ vauchers: [], pagination: DEFAULT_PAGINATION });
  const [cargando, setCargando] = useState(false);
  const [mensajeError, setMensajeError] = useState(false);
  const [confirmVaucher, setConfirmVaucher] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const pagina = searchParams.get('page') || 1;
  const pageSize = searchParams.get('per_page') || '10';

  const parseFilters = () => {
    const filtros = {};
    [
      'filtroFecha',
      'filtroBarco',
      'filtroFirmado',
      'filtroSolicitadoPor',
      'filtroTipoVaucher',
      'numeroDesde',
      'numeroHasta',
    ].forEach((nombre) => {
      filtros[nombre] = searchParams.get(nombre) || null;
    });
    return filtros;
  };

  const filtros = parseFilters();

  useEffect(() => {
    setCargando(true);
    getVauchers(
      pageSize,
      pagina,
      filtros,
      (info) => {
        setData(info);
        setCargando(false);
      },
      (error) => {
        setMensajeError(<Error error={error} />);
        setCargando(false);
      }
    );
  }, [searchParams]);

  const handlePageChange = (e, d) => {
    searchParams.set('page', d.activePage);
    setSearchParams(searchParams);
  };

  // eslint-disable-next-line no-unused-vars
  const handleConfirmFirmar = () => {
    const { vid } = confirmVaucher;
    firmarVaucher(
      vid,
      (resultado) => {
        setData((old) => {
          const nuevoArray = old.vauchers.map((v) =>
            v.id === resultado.id ? { ...v, ...resultado } : v
          );
          return { ...old, vauchers: nuevoArray };
        });
        setConfirmVaucher(null);
      },
      (mensaje) => {
        setMensajeError(mensaje);
      }
    );
  };

  // eslint-disable-next-line no-unused-vars
  const handleConfirmAnular = () => {
    const { vid } = confirmVaucher;
    updateVaucher(
      vid,
      { inactivo: true },
      (resultado) => {
        setData((old) => {
          const nuevoArray = old.vauchers.map((v) =>
            v.id === resultado.id ? { ...v, ...resultado } : v
          );
          return { ...old, vauchers: nuevoArray };
        });
        setConfirmVaucher(null);
      },
      (mensaje) => {
        setMensajeError(mensaje);
      }
    );
  };

  const handleConfirm = () => {
    if (confirmVaucher.target === 'firmar') handleConfirmFirmar();
    if (confirmVaucher.target === 'anular') handleConfirmAnular();
  };

  const handleFirmar = (vid, numero) => {
    setConfirmVaucher({ vid, numero, target: 'firmar' });
  };

  const handleAnular = (vid, numero) => {
    setConfirmVaucher({ vid, numero, target: 'anular' });
  };

  const handleFiltersChanged = (filters) => {
    const newFilters = {};
    Object.keys(filters).forEach((name) => {
      if (filters[name] === null) return;
      if (filters[name] === '') return;
      if (filters[name] instanceof Date) {
        newFilters[name] = format(filters[name], 'yyyy-MM-dd');
      } else newFilters[name] = filters[name];
    });
    setSearchParams(newFilters);
  };
  const handleReset = () => {
    setSearchParams({});
  };

  const getServices = (items) => {
    const services = items.map((item) => item.servicio.nombre);
    return [...new Set(services)];
  };

  const handlePageSizeChange = (e, d) => {
    searchParams.set('per_page', d.value);
    setSearchParams(searchParams);
  };

  return (
    <>
      <div style={{ margin: '10px 10px' }}>
        {mensajeError}
        <Header size="large">Vauchers</Header>
        <Filters values={filtros} onChange={handleFiltersChanged} onReset={handleReset} />
        <br />
        <Button primary as="a" href="/crear" style={{ marginBottom: '10px' }}>
          Crear
        </Button>
        <Dimmer.Dimmable dimmed={cargando}>
          <Table selectable compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="99">
                  <span>Mostrar &nbsp;</span>
                  <Dropdown
                    compact
                    selection
                    options={PAGE_SIZES}
                    onChange={handlePageSizeChange}
                    value={pageSize}
                  />
                  <span>&nbsp;Registros</span>
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell width={1}>Número</Table.HeaderCell>
                <Table.HeaderCell width={1}>Fecha</Table.HeaderCell>
                <Table.HeaderCell width={2}>Tipo</Table.HeaderCell>
                <Table.HeaderCell width={2}>Embarcacion</Table.HeaderCell>
                <Table.HeaderCell>Servicios Prestados</Table.HeaderCell>
                <Table.HeaderCell width={2}>Solicitado Por</Table.HeaderCell>
                <Table.HeaderCell textAlign="right" width={3}>
                  Opciones
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.vauchers.length === 0 && (
                <Table.Row>
                  <Table.Cell colSpan={99} textAlign="center">
                    No existen registros para mostrar
                  </Table.Cell>
                </Table.Row>
              )}
              {data.vauchers.map((voucher) => (
                <Table.Row key={voucher.id}>
                  <Table.Cell textAlign="right">{voucher.numero}</Table.Cell>
                  <Table.Cell>{format(voucher.fecha, 'dd/MM/yyyy')}</Table.Cell>
                  <Table.Cell>{voucher.tipo_vaucher.nombre}</Table.Cell>
                  <Table.Cell>{voucher.nombre_barco}</Table.Cell>
                  <Table.Cell>
                    <Popup
                      wide="very"
                      trigger={
                        <div>
                          {getServices(voucher.items_vaucher_attributes).map((servicio) => (
                            <Label size="mini">{servicio}</Label>
                          ))}
                        </div>
                      }
                    >
                      <Header size="small">Servicios</Header>
                      <ul style={{ padding: '0px 10px 0px 10px', listStyleType: 'none' }}>
                        {voucher.items_vaucher_attributes.map((item) => (
                          <li>
                            {item.cantidad} <strong>{item.servicio.nombre}</strong> prestado entre
                            las {item.hora_inicio} y las {item.hora_fin}
                            <blockquote
                              style={{ margin: '5px 10px', fontStyle: 'italic', color: 'dimgrey' }}
                            >
                              {item.descripcion}
                            </blockquote>
                          </li>
                        ))}
                      </ul>
                    </Popup>
                  </Table.Cell>
                  <Table.Cell>{voucher.solicitado_por}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <ButtonGroup>
                      <Button size="mini" href={`/vaucher/${voucher.id}`}>
                        Ver
                      </Button>
                      <Button
                        size="mini"
                        href={`/vaucheres/${voucher.id}`}
                        disabled={voucher.facturado}
                      >
                        Editar
                      </Button>
                      <Button
                        size="mini"
                        disabled={voucher.firmado === true}
                        onClick={() => handleFirmar(voucher.id, voucher.numero)}
                      >
                        Firmar
                      </Button>
                      <Button
                        size="mini"
                        negative
                        disabled={voucher.facturado || voucher.inactivo}
                        onClick={() => handleAnular(voucher.id, voucher.numero)}
                      >
                        Anular
                      </Button>
                    </ButtonGroup>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.Cell colSpan={2}>{data.pagination.records} Vaucher(s)</Table.Cell>
                <Table.Cell colSpan={99} textAlign="right">
                  <Pagination
                    activePage={data.pagination.current_page}
                    totalPages={data.pagination.total_pages}
                    onPageChange={handlePageChange}
                    ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                    firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                    lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                    prevItem={{ content: <Icon name="angle left" />, icon: true }}
                    nextItem={{ content: <Icon name="angle right" />, icon: true }}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
          <Dimmer active={cargando} inverted>
            <Loader active inverted>
              Cargando
            </Loader>
          </Dimmer>
        </Dimmer.Dimmable>
      </div>
      <Confirm
        open={confirmVaucher !== null}
        content={`Se procedera a ${confirmVaucher?.target} el vaucher #${confirmVaucher?.numero}`}
        onCancel={() => setConfirmVaucher(null)}
        onConfirm={handleConfirm}
        header={`${confirmVaucher?.target} vaucher`.toUpperCase()}
        confirmButton="Confirmar"
        cancelButton="Cancelar"
      />
    </>
  );
};

export default VauchersIndex;
