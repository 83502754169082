import React, { useState, useEffect } from 'react';
import { Form, Button, Header, Segment, Message } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import ServiceForm from './partials/ServiceForm';
import Error from '../Error';
import {
  getBarcos,
  EMPTY_SERVICE,
  EMPTY_VAUCHER,
  getVaucher,
  createVaucher,
  updateVaucher,
  getTiposVaucher,
} from '../../hooks/apiManager';
import ServiceList from './partials/ServiceList';

const VauchersForm = () => {
  const { vaucherId } = useParams();
  const [searchParams] = useSearchParams();

  const [state, setState] = useState(EMPTY_VAUCHER);
  const [barcos, setBarcos] = useState([]);
  const [tiposVaucher, setTiposVaucher] = useState([]);
  const [guardando, setGuardando] = useState(false);
  const [mensajeError, setMensajeError] = useState(null);
  const [mensajeExito, setMensajeExito] = useState(false);
  const [cargando, setCargando] = useState(false);
  const navigate = useNavigate();

  const handleChangeBarco = (e, d) => {
    setState((oldState) => ({ ...oldState, nombre_barco: d.value }));
  };

  const handleChangeTipoVaucher = (e, d) => {
    setState((oldState) => ({ ...oldState, tipo_vaucher_id: d.value }));
  };

  const handleCancelar = () => {
    setState((oldState) => ({ ...oldState, is_editing: false, edited_record: 0 }));
  };

  const handleNuevo = () => {
    setState((oldState) => ({ ...oldState, is_editing: true, edited_record: 0 }));
  };

  const handleEditar = (e) => {
    setState((oldState) => ({ ...oldState, is_editing: true, edited_record: e }));
  };

  const handleLimpiar = () => {
    setState(EMPTY_VAUCHER);
  };

  const deshabilitaGuardar = () =>
    state.fecha === undefined ||
    state.fecha === null ||
    state.items_vaucher_attributes.length === 0 ||
    state.tipo_vaucher_id === null ||
    state.tipo_vaucher_id === 0 ||
    guardando;

  const handleChangeSolicitadoPor = (e, d) => {
    setState((oldState) => ({ ...oldState, solicitado_por: d.value }));
  };

  const markForDestroyById = (items, id) =>
    items.map((item) => (item.id === id ? { ...item, destroy: 1 } : item));

  const removeItemById = (items, id) => items.filter((item) => item.key !== id);

  const handleBorrar = (id) => {
    const item = state.items_vaucher_attributes.find((i) => i.key === id);
    if (item.id) {
      setState((oldState) => ({
        ...oldState,
        items_vaucher_attributes: markForDestroyById(oldState.items_vaucher_attributes, id),
      }));
    } else {
      setState((oldState) => ({
        ...oldState,
        items_vaucher_attributes: removeItemById(oldState.items_vaucher_attributes, id),
      }));
    }
  };

  const updateItem = (itemsServicio, nuevoItem) =>
    itemsServicio.map((item) => {
      if (
        (item.id === nuevoItem.id && typeof nuevoItem.id !== 'undefined') ||
        (item.key === nuevoItem.key && typeof (nuevoItem.key !== 'undefined'))
      )
        return { ...item, ...nuevoItem };
      return item;
    });

  const registroEditable = () => {
    const servicio = state.items_vaucher_attributes.filter(
      (item) => item.id === state.edited_record || item.key === state.edited_record
    );
    if (servicio.length === 0) return EMPTY_SERVICE;
    return servicio[0];
  };

  const handleGuardarItem = (item) => {
    setState((oldState) => ({
      ...oldState,
      items_vaucher_attributes:
        item.id !== undefined || item.key !== undefined
          ? updateItem(oldState.items_vaucher_attributes, item)
          : oldState.items_vaucher_attributes.concat({ ...item, key: new Date().getTime() }),
      is_editing: false,
      edited_record: 0,
    }));
  };

  const transformVaucher = () => {
    const info = {
      fecha: format(state.fecha, 'yyyy-MM-dd'),
      nombre_barco: state.nombre_barco,
      solicitado_por: state.solicitado_por,
      tipo_vaucher_id: state.tipo_vaucher_id,
      items_vaucher_attributes: state.items_vaucher_attributes.map((item) => ({
        _destroy: item.destroy,
        hora_inicio: item.hora_inicio,
        hora_fin: item.hora_fin,
        servicio_id: item.servicio.id,
        descripcion: item.descripcion,
        id: item.id,
        cantidad: item.cantidad,
      })),
    };
    return info;
  };

  const handleModificar = () => {
    setGuardando(true);
    setMensajeError(false);
    const vaucherInfo = transformVaucher();
    updateVaucher(
      vaucherId,
      vaucherInfo,
      (newState) => {
        setGuardando(false);
        setMensajeExito('Modificación exitosa');
        setState(newState);
        navigate(`/vaucher/${vaucherId}?exito=1`);
      },
      (error) => {
        setMensajeError(<Error error={error} />);
        setGuardando(false);
      }
    );
  };

  const handleGuardar = () => {
    setGuardando(true);
    setMensajeError(false);

    const vaucherData = transformVaucher();

    createVaucher(
      vaucherData,
      (newVaucherId) => {
        setGuardando(false);
        navigate(`/vaucher/${newVaucherId}?exito=1`);
        setGuardando(false);
      },
      (result) => {
        if (result.errors) setMensajeError(<Error errors={result.errors} />);
        else setMensajeError(result.message);

        setGuardando(false);
      }
    );
  };

  useEffect(() => {
    getBarcos(
      null,
      (data) => setBarcos(data),
      (error) => setMensajeError(error)
    );
    getTiposVaucher(
      (data) => setTiposVaucher(data),
      (error) => error
    );
  }, []);

  useEffect(() => {
    if (typeof vaucherId === 'undefined') return;
    setCargando(true);
    getVaucher(
      vaucherId,
      (vaucherInfo) => {
        setState(vaucherInfo);
        setCargando(false);
        const exito = searchParams.get('exito');
        if (exito) {
          setMensajeExito('Creación Exitosa');
        }
      },
      (error) => {
        setMensajeError(error);
        setCargando(false);
      }
    );
  }, [vaucherId]);

  const setFecha = (event, data) => {
    setState((oldState) => ({ ...oldState, fecha: data.value }));
  };

  const handleCheckEmbarcacion = (e, d) => {
    setState((old) => ({ ...old, embarcacion_no_listada: d.checked }));
  };

  useEffect(() => {
    let noListado = state.embarcacion_no_listada;
    if (barcos?.length > 0 && vaucherId) {
      const nombre = state.nombre_barco.toLowerCase().trim();
      noListado = !barcos.some((barco) => barco.text.toLowerCase() === nombre);
    }
    setState((old) => ({ ...old, embarcacion_no_listada: noListado }));
  }, [barcos]);

  return (
    <>
      <Segment basic>
        {mensajeError}
        {mensajeExito && (
          <Message
            success
            onDismiss={() => {
              setMensajeExito(false);
            }}
          >
            {mensajeExito}
          </Message>
        )}
        <Form loading={cargando}>
          <Header>
            {state.id === 0 ? 'Crear Vaucher de Servicio' : 'Editar Vaucher Servicio'}
          </Header>
          <Form.Group>
            <SemanticDatepicker
              disabled={state.is_editing}
              label="Fecha"
              locale="es-ES"
              value={state.fecha}
              format="DD/MM/YYYY"
              onChange={setFecha}
              width="three"
            />
            <Form.Dropdown
              width="three"
              search
              selection
              label="Tipo Vaucher"
              value={state.tipo_vaucher_id}
              options={tiposVaucher}
              onChange={handleChangeTipoVaucher}
            />
            <Form.Dropdown
              clearable
              search
              selection
              label="Buque"
              width="six"
              options={barcos}
              value={state.nombre_barco}
              onChange={handleChangeBarco}
              disabled={state.is_editing || state.embarcacion_no_listada}
            />
            <Form.Input
              label="Solicitado por"
              maxLength={50}
              onChange={handleChangeSolicitadoPor}
              value={state.solicitado_por}
              width="four"
            />
          </Form.Group>
          <Form.Group>
            <Form.Checkbox
              label="Embarcación no listada"
              checked={state.embarcacion_no_listada}
              onChange={handleCheckEmbarcacion}
            />
          </Form.Group>
          <Form.Group>
            {state.embarcacion_no_listada && (
              <Form.Input
                label="Embarcacion"
                maxLength={50}
                onChange={handleChangeBarco}
                value={state.nombre_barco}
                width="four"
              />
            )}
          </Form.Group>
        </Form>
        <Header>Servicios</Header>
        <Button disabled={state.nombre_barco === ''} icon="add" as="a" onClick={handleNuevo}>
          Agregar
        </Button>
        <ServiceList
          className="content-area"
          servicios={state.items_vaucher_attributes}
          onEdit={handleEditar}
          onDelete={handleBorrar}
        />

        <ServiceForm
          servicio={registroEditable()}
          itemId={state.edited_record}
          onCancelar={handleCancelar}
          onGuardar={handleGuardarItem}
          visible={state.is_editing}
        />
        {state.id === 0 && (
          <Button
            primary
            onClick={handleGuardar}
            disabled={deshabilitaGuardar()}
            loading={guardando}
          >
            Crear
          </Button>
        )}
        {state.id !== 0 && (
          <Button primary onClick={handleModificar} disabled={guardando} loading={guardando}>
            Guardar Cambios
          </Button>
        )}
        {state.id === 0 && (
          <Button onClick={handleLimpiar} basic>
            Limpiar
          </Button>
        )}
        {state.id !== 0 && (
          <Button as="a" href="/crear" basic>
            Crear Otro
          </Button>
        )}
      </Segment>
    </>
  );
};

export default VauchersForm;
