import React from 'react';
import { Table } from 'semantic-ui-react';
import ServiceListItem from './ServiceListItem';

const ServiceList = (props) => {
  const { servicios, onEdit, onDelete } = props;

  const items = servicios
    // eslint-disable-next-line no-underscore-dangle
    .filter((t) => typeof t.destroy === 'undefined')
    .map((t) => <ServiceListItem key={t.id} servicio={t} onEdit={onEdit} onDelete={onDelete} />);
  return (
    <Table compact striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1}>Cantidad</Table.HeaderCell>
          <Table.HeaderCell width={3}>Servicio</Table.HeaderCell>
          <Table.HeaderCell>Detalle</Table.HeaderCell>
          <Table.HeaderCell width={2}>Hora Inicio</Table.HeaderCell>
          <Table.HeaderCell width={2}>Hora Fin</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Opciones</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.length === 0 && (
          <Table.Row>
            <Table.Cell colSpan={99} textAlign="center">
              No existen servicios para mostrar
            </Table.Cell>
          </Table.Row>
        )}
        {items}
      </Table.Body>
    </Table>
  );
};

export default ServiceList;
