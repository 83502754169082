import React, { useEffect, useMemo, useState } from 'react';
import { Form, Button, Segment } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import PropTypes from 'prop-types';
import { parse } from 'date-fns';
import Cleave from 'cleave.js/react';
import { getBarcos, getTiposVaucher } from '../../hooks/apiManager';

const opcionesFirma = [
  { text: 'Firmados', value: '1' },
  { text: 'No Firmados', value: '0' },
  { text: 'Todos', value: '-1' },
];

const FILTROS_INICIALES = {
  filtroFecha: null,
  filtroBarco: null,
  filtroFirmado: null,
  filtroTipoVaucher: null,
  filtroSolicitadoPor: '',
  numeroDesde: '',
  numeroHasta: '',
};

const Filters = ({ values, onChange, onReset }) => {
  const [opcionesBarcos, setOpcionesBarcos] = useState([]);
  const [opcionesTiposVaucher, setOpcionesTiposVaucher] = useState([]);
  const [filtros, setFiltros] = useState({ ...FILTROS_INICIALES });

  useEffect(() => {
    let newValue = null;
    if (values) {
      const { filtroFecha } = values;
      if (filtroFecha && filtroFecha !== null) {
        if (filtroFecha.constructor === Date) {
          newValue = filtroFecha;
        } else if (filtroFecha.constructor === String) {
          newValue = parse(filtroFecha, 'yyyy-MM-dd', new Date());
        }
      }
    }
    setFiltros({ ...values, filtroFecha: newValue });
  }, []);

  useEffect(() => {
    getBarcos(
      null,
      (opciones) => setOpcionesBarcos(opciones),
      () => {}
    );
    getTiposVaucher(
      (data) => setOpcionesTiposVaucher(data),
      () => {}
    );
  }, []);

  const handleChange = (e, d) => {
    setFiltros((old) => {
      const newObject = { ...old, [d.name]: d.value };
      return newObject;
    });
  };

  const handleReset = () => {
    setFiltros(FILTROS_INICIALES);
    onReset(null);
  };

  const handleSubmit = () => {
    onChange(filtros);
  };

  const handleChangeNumeroDesde = (e) => {
    setFiltros((old) => ({ ...old, numeroDesde: e.target.rawValue }));
  };

  const handleChangeNumeroHasta = (e) => {
    setFiltros((old) => ({ ...old, numeroHasta: e.target.rawValue }));
  };

  const renderComponent = (
    <Segment>
      <Form>
        <Form.Group>
          <SemanticDatepicker
            name="filtroFecha"
            width="four"
            label="Fecha"
            locale="es-ES"
            value={filtros.filtroFecha}
            format="DD/MM/YYYY"
            onChange={handleChange}
          />
          <Form.Dropdown
            name="filtroBarco"
            search
            clearable
            selection
            label="Buque"
            width="five"
            options={opcionesBarcos}
            value={filtros.filtroBarco}
            onChange={handleChange}
          />
          <Form.Dropdown
            name="filtroTipoVaucher"
            label="Tipo Vaucher"
            search
            clearable
            selection
            width="two"
            options={opcionesTiposVaucher}
            value={filtros.filtroTipoVaucher}
            onChange={handleChange}
          />
          <Form.Dropdown
            name="filtroFirmado"
            search
            clearable
            selection
            label="Estado Firma"
            width="two"
            options={opcionesFirma}
            value={filtros.filtroFirmado}
            onChange={handleChange}
          />
          <Form.Input
            name="filtroSolicitadoPor"
            label="Solicitado Por"
            value={filtros.filtroSolicitadoPor || ''}
            onChange={handleChange}
            width="four"
          />
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label htmlFor="#numeroDesde">Numero Desde</label>
            <Cleave
              id="numeroDesde"
              name="numeroDesde"
              options={{
                numericOnly: true,
              }}
              onChange={handleChangeNumeroDesde}
              value={filtros.numeroDesde}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="#numeroHasta">Numero Desde</label>
            <Cleave
              id="numeroHasta"
              name="numeroHasta"
              options={{
                numericOnly: true,
              }}
              onChange={handleChangeNumeroHasta}
              value={filtros.numeroHasta}
            />
          </Form.Field>
        </Form.Group>
        <Button onClick={handleSubmit}>Consultar</Button>
        <Button onClick={handleReset} basic>
          Limpiar
        </Button>
      </Form>
    </Segment>
  );

  return useMemo(() => renderComponent, [filtros, opcionesBarcos, opcionesTiposVaucher]);
};

Filters.propTypes = {
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default Filters;
