import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import Cleave from 'cleave.js/react';
import { EMPTY_SERVICE, getServicios } from '../../../hooks/apiManager';

const ServiceForm = (props) => {
  const { servicio, onCancelar, onGuardar, className, visible } = props;

  const [editorState, setEditorState] = useState(servicio);

  const [opcionesListaServicios, setOpcionesListaServicios] = useState([]);

  const [validacion, setValidacion] = useState({
    errorCantidad: false,
    errorDescripcion: false,
    errorHoraInicio: false,
    errorHoraFin: false,
    errorServicio: false,
    errorVacio: false,
  });

  const handleChangeHora = (event) => {
    setEditorState((oldState) => ({ ...oldState, [event.target.name]: event.target.value }));
    const key = event.target.name === 'hora_inicio' ? 'errorHoraInicio' : 'errorHoraFin';

    setValidacion((old) => ({
      ...old,
      [key]: event.target.value.length !== 5,
    }));
  };

  const handleChangeCantidad = (event) => {
    setEditorState((oldState) => ({ ...oldState, cantidad: event.target.value }));
    setValidacion((old) => ({
      ...old,
      errorCantidad:
        Number.isNaN(event.target.value) || event.target.value === '' || event.target.value === '0',
    }));
  };

  const handleChangeServicio = (e, d) => {
    const nuevoServicio = opcionesListaServicios.filter((opcion) => opcion.key === d.value)[0];
    setEditorState((oldState) => ({
      ...oldState,
      servicio: {
        id: nuevoServicio.key,
        nombre: nuevoServicio.text,
      },
    }));
  };

  const handleChangeDescripcion = (event) => {
    setEditorState((oldState) => ({ ...oldState, descripcion: event.target.value }));
    setValidacion((old) => ({
      ...old,
      errorDescripcion: event.target.value === '',
    }));
  };

  const handleGuardar = () => {
    onGuardar(editorState);
    setEditorState(EMPTY_SERVICE);
  };

  useEffect(() => {
    setEditorState(servicio);
  }, [servicio]);

  useEffect(() => {
    setValidacion((old) => ({ ...old, errorVacio: editorState === EMPTY_SERVICE }));
  }, [editorState]);

  useEffect(() => {
    getServicios((opciones) => setOpcionesListaServicios(opciones));
  }, []);

  return (
    <div className={className}>
      <Modal open={visible}>
        <Modal.Header>Editar Servicio</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Field error={validacion.errorCantidad}>
                <label htmlFor="#cantidad">Cantidad</label>
                <Cleave
                  id="cantidad"
                  name="cantidad"
                  options={{
                    numericOnly: true,
                    numeral: true,
                    numeralIntegerScale: 4,
                    numeralThousandsGroupStyle: 'none',
                    numeralPositiveOnly: true,
                  }}
                  onChange={handleChangeCantidad}
                  value={editorState.cantidad}
                />
              </Form.Field>
              <Form.Dropdown
                width="sixteen"
                search
                selection
                label="Servicio"
                options={opcionesListaServicios}
                onChange={handleChangeServicio}
                value={editorState.servicio.id}
              />
            </Form.Group>
            <Form.Group unstackable widths="equal">
              <Form.Field error={validacion.errorHoraInicio}>
                <label htmlFor="#hora_inicio">Hora Inicio</label>
                <Cleave
                  id="hora_inicio"
                  name="hora_inicio"
                  options={{ time: true, timePattern: ['h', 'm'] }}
                  onChange={handleChangeHora}
                  value={editorState.hora_inicio}
                />
              </Form.Field>
              <Form.Field error={validacion.errorHoraFin}>
                <label htmlFor="#hora_fin">Hora Fin</label>
                <Cleave
                  id="hora_fin"
                  name="hora_fin"
                  options={{ time: true, timePattern: ['h', 'm'] }}
                  onChange={handleChangeHora}
                  value={editorState.hora_fin}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.TextArea
                label="Descripcion"
                width="sixteen"
                onChange={handleChangeDescripcion}
                value={editorState.descripcion}
                error={validacion.errorDescripcion}
              />
            </Form.Group>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button
            primary
            onClick={handleGuardar}
            disabled={Object.keys(validacion)
              .map((key) => validacion[key] === true)
              .find((v) => v === true)}
          >
            Aceptar
          </Button>
          <Button onClick={onCancelar}>Cancelar</Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default ServiceForm;
