import React from 'react';
import { Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const ulStyle = {
  padding: '0',
  listStyleType: 'none',
};

const Error = ({ error }) => {
  const properize = (name) => {
    const attrName = name.replace(/_id$/, '');
    return attrName
      .split('_')
      .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1))
      .join('');
  };

  const data = error?.response?.data?.errors || error?.response?.data;

  const list = () => {
    if (Array.isArray(data)) {
      return data.map((item) => <li>{item}</li>);
    }
    const keys = Object.keys(data);
    return keys.map((key) =>
      data[key].map((subitem) => (
        <li>
          <strong>{properize(key)}</strong> {subitem}
        </li>
      ))
    );
  };

  const statusCode = error?.response?.status || -1;

  return (
    <Message negative>
      <Message.Content>
        {statusCode === 422 && <ul style={ulStyle}>{list()}</ul>}
        {statusCode === 500 && 'Error Interno'}
        {statusCode === -1 && <span>Error Inesperado {error.message}</span>}
      </Message.Content>
    </Message>
  );
};

Error.propTypes = {
  error: PropTypes.shape({
    response: PropTypes.shape({
      data: PropTypes.shape({}),
      status: PropTypes.number,
    }),
    message: PropTypes.string,
  }).isRequired,
};

export default Error;
