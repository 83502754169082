import React from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import './App.css';

import Login from './components/Login';

import VauchersForm from './components/Vouchers/Form';
import VouchersIndex from './components/Vouchers/Index';
import VaucherShow from './components/Vouchers/Show';
import TopMenu from './components/TopMenu';

import { mustRedirectToLogin } from './hooks/apiManager';
import Viewport from './components/Viewport';

const App = () => {
  if (mustRedirectToLogin()) {
    return <Login />;
  }

  return (
    <Viewport>
      <TopMenu />
      <Router>
        <Routes>
          <Route path="/" element={<VouchersIndex />} />
          <Route path="/login" element={<Login />} />
          <Route path="/crear" element={<VauchersForm />} />
          <Route path="/vaucheres/:vaucherId" element={<VauchersForm />} />
          <Route path="/vaucher/:vaucherId" element={<VaucherShow />} />
        </Routes>
      </Router>
    </Viewport>
  );
};

export default App;
