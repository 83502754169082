import React from 'react';
import { Menu, Icon, Dropdown } from 'semantic-ui-react';
import 'fomantic-ui-css/semantic.css';
import { logOut, currentLogin } from '../hooks/apiManager';
import generatedGitInfo from '../generatedGitInfo.json';

const TopMenu = () => {
  const handleLogout = () => {
    logOut();
    window.location.href = '/';
  };
  return (
    <Menu inverted fluid className="topBar">
      <Menu.Item as="a" href="/">
        <Icon name="home" />
      </Menu.Item>
      <Menu.Menu position="right">
        <Menu.Item>{generatedGitInfo.gitCommitHash || 'development'}</Menu.Item>
        <Menu.Item>
          <Dropdown text={currentLogin()} pointing="top right">
            <Dropdown.Menu>
              <Dropdown.Item icon="cog" text="Preferencias" as="a" href="/prefs" />
              <Dropdown.Item icon="log out" text="Cerrar Sesión" as="a" onClick={handleLogout} />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};

export default TopMenu;
