import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Confirm, Header, Message, Segment, Table } from 'semantic-ui-react';
import { EMPTY_VAUCHER, firmarVaucher, getVaucher, updateVaucher } from '../../hooks/apiManager';

const VauchersShow = () => {
  const { vaucherId } = useParams();
  const [state, setState] = useState(EMPTY_VAUCHER);
  const [cargando, setCargando] = useState(false);
  const [mensajeError, setMensajeError] = useState(null);
  const [confirmVaucher, setConfirmVaucher] = useState(null);
  useEffect(() => {
    if (typeof vaucherId === 'undefined') return;
    setCargando(true);
    getVaucher(
      vaucherId,
      (vaucherInfo) => {
        setState(vaucherInfo);
        setCargando(false);
      },
      (error) => {
        setMensajeError(error);
        setCargando(false);
      }
    );
  }, [vaucherId]);

  // eslint-disable-next-line no-unused-vars
  const handleConfirmFirmar = () => {
    firmarVaucher(
      vaucherId,
      (resultado) => {
        setState((old) => ({ ...old, ...resultado }));
        setConfirmVaucher(null);
      },
      (mensaje) => {
        setMensajeError(mensaje);
      }
    );
  };

  // eslint-disable-next-line no-unused-vars
  const handleConfirmAnular = () => {
    updateVaucher(
      vaucherId,
      { inactivo: true },
      (resultado) => {
        setState((old) => ({ ...old, ...resultado }));
        setConfirmVaucher(null);
      },
      (mensaje) => {
        setMensajeError(mensaje);
      }
    );
  };

  // eslint-disable-next-line no-unused-vars
  const handleConfirmDesAnular = () => {
    updateVaucher(
      vaucherId,
      { inactivo: false },
      (resultado) => {
        setState((old) => ({ ...old, ...resultado }));
        setConfirmVaucher(null);
      },
      (mensaje) => {
        setMensajeError(mensaje);
      }
    );
  };

  const handleConfirm = () => {
    if (confirmVaucher.target === 'firmar') handleConfirmFirmar();
    if (confirmVaucher.target === 'anular') handleConfirmAnular();
    if (confirmVaucher.target === 'reactivar') handleConfirmDesAnular();
  };

  const handleFirmar = (vid, numero) => {
    setConfirmVaucher({ vid, numero, target: 'firmar' });
  };

  const handleAnular = (vid, numero) => {
    setConfirmVaucher({ vid, numero, target: 'anular' });
  };

  const handleDesAnular = (vid, numero) => {
    setConfirmVaucher({ vid, numero, target: 'reactivar' });
  };

  return (
    <>
      {mensajeError && <Message negative>{mensajeError}</Message>}
      <Segment loading={cargando} basic>
        <Header>VAUCHER #{state.numero}</Header>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={2}>Fecha</Table.Cell>
              <Table.Cell>{state.fecha && format(state.fecha, 'dd/MM/yyyy')}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Tipo Vaucher</Table.Cell>
              <Table.Cell>{state.tipo_vaucher_nombre}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Barco</Table.Cell>
              <Table.Cell>{state.nombre_barco}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Solicitado Por</Table.Cell>
              <Table.Cell>{state.solicitado_por}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Barco</Table.Cell>
              <Table.Cell>{state.nombre_barco}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Estado</Table.Cell>
              <Table.Cell>{state.estado}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Header>Servicios</Header>
        <Table selectable striped compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>Cantidad</Table.HeaderCell>
              <Table.HeaderCell width={3}>Servicio</Table.HeaderCell>
              <Table.HeaderCell>Detalle</Table.HeaderCell>
              <Table.HeaderCell width={2}>Hora Inicio</Table.HeaderCell>
              <Table.HeaderCell width={2}>Hora Fin</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {state.items_vaucher_attributes.map((item) => (
              <Table.Row key={item.id}>
                <Table.Cell textAlign="right">{item.cantidad}</Table.Cell>
                <Table.Cell>{item.servicio.nombre}</Table.Cell>
                <Table.Cell>{item.descripcion}</Table.Cell>
                <Table.Cell>{item.hora_inicio}</Table.Cell>
                <Table.Cell>{item.hora_fin}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Button disabled={state.firmado} onClick={handleFirmar}>
          Firmar
        </Button>
        <Button href={`/vaucheres/${vaucherId}`} disabled={state.facturado}>
          Editar
        </Button>
        <Button negative disabled={state.facturado || state.inactivo} onClick={handleAnular}>
          Anular
        </Button>
        <Button disabled={!state.inactivo} onClick={handleDesAnular}>
          Reactivar
        </Button>
        <Button href="/crear">Crear Otro</Button>
      </Segment>
      <Confirm
        open={confirmVaucher !== null}
        content={`Se procedera a ${confirmVaucher?.target} el vaucher #${state.numero}`}
        onCancel={() => setConfirmVaucher(null)}
        onConfirm={handleConfirm}
        header={`${confirmVaucher?.target} vaucher`.toUpperCase()}
        confirmButton="Confirmar"
        cancelButton="Cancelar"
      />
    </>
  );
};

export default VauchersShow;
