import React, { useState } from 'react';
import { Button, Form, Grid, Header, Icon, Label, Message, Segment } from 'semantic-ui-react';
import 'fomantic-ui-css/semantic.css';
import { loginUser, isLoggedIn } from '../hooks/apiManager';
import generatedGitInfo from '../generatedGitInfo.json';

const Login = () => {
  const [state, setState] = useState({ login: '', password: '', message: '' });
  const [cargando, setCargando] = useState(false);

  if (isLoggedIn()) window.location.href = '/';

  const handleLogin = async (e) => {
    e.preventDefault();
    setCargando(true);
    loginUser(state, (result) => {
      if (result.status === 'OK') {
        window.location.href = '/';
      } else {
        setState((prevState) => ({ ...prevState, message: result.message }));
        setCargando(false);
      }
    });
  };

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" primary textAlign="center">
          <Icon name="user" />
          Iniciar Sesion
        </Header>
        {process.env.NODE_ENV === 'development' && (
          <Label color="red" basic>
            Development {generatedGitInfo.gitCommitHash || ' no-commit'}
          </Label>
        )}
        {state.message !== '' && <Message negative>{state.message}</Message>}
        <Form loading={cargando}>
          <Segment basic>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="usuario o correo electronico"
              onChange={(event) => setState({ ...state, login: event.target.value })}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="contraseña"
              type="password"
              onChange={(event) => setState({ ...state, password: event.target.value })}
            />
            <Button primary fluid size="large" onClick={handleLogin}>
              Login
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default Login;
