import axiosClient from './axiosClient';

// const TITULARES = '/titulares.json';
// const BARCOS = '/barcos.json';
const LOGIN = '/login';
// const LOGOUT = '/logout';
const EMPTY_SERVICE = {
  servicio: {
    id: null,
    nombre: null,
    requiere_lancha: false,
  },
  lancha: {
    id: null,
    nombre: null,
  },
  hora_inicio: '',
  hora_fin: '',
  descripcion: '',
  cantidad: 1,
};

const EMPTY_VAUCHER = {
  fecha: null,
  titular_id: 0,
  nombre_barco: '',
  id: 0,
  items_vaucher_attributes: [],
  is_editing: false,
  solicitado_por: '',
  edited_record: 0,
  tipo_vaucher_id: 0,
  embarcacion_no_listada: false,
};

const parseDate = (date) => {
  if (date === null) return null;
  const values = date.split('-');
  return new Date(values[0], values[1] - 1, values[2]);
};

const parseError = (error, callback) => {
  if (error.response) {
    switch (error.response.status) {
      case 424:
        callback(error.response.data);
        break;
      default:
        callback(`${error.response.status} ${error.response.statusText}`);
    }
  } else {
    callback(error.message);
  }
};

const getURL = (route) => process.env.REACT_APP_API_ENDPOINT + route;

const currentLogin = () => localStorage.getItem('evc-login');
const currentToken = () => localStorage.getItem('evc-token');

const loginUser = (credentials, callback) => {
  axiosClient
    .post(getURL(LOGIN), {
      login: credentials.login,
      password: credentials.password,
    })
    .then((response) => {
      localStorage.setItem('evc-token', response.data.token);
      localStorage.setItem('evc-login', response.data.login);
      localStorage.setItem('evc-user', response.data.name);

      callback({ ...response.data, status: 'OK' });
    })
    .catch((error) => {
      if (error.response) {
        const { response } = error;
        callback({ message: response.data.error, status: 'ERROR' });
      } else if (error.request) {
        callback({ message: error.message });
      } else {
        callback({ message: error.message });
      }
    });
};

const getClientes = (dataCallback, errorCallback) => {
  axiosClient
    .get(getURL('/titulares.json'), {
      headers: {
        Authorization: currentToken(),
      },
    })
    .then((response) => {
      const newData = response.data.map((cliente) => ({
        key: cliente.id,
        value: cliente.id,
        text: cliente.razon_social,
      }));
      dataCallback(newData);
    })
    .catch((error) => {
      if (error.response) {
        errorCallback(error.response.data);
      } else {
        errorCallback(error.message);
      }
    });
};

const getTiposVaucher = (dataCallback, errorCallback) => {
  axiosClient
    .get(getURL('/tipos_vaucher.json'), {
      headers: { Authorization: currentToken() },
    })
    .then((response) => {
      const newData = response.data.map((tipo) => ({
        key: tipo.id,
        value: tipo.id,
        text: tipo.nombre,
      }));
      dataCallback(newData);
    })
    .catch((error) => {
      parseError(error, errorCallback);
    });
};

const getBarcos = (titularId, dataCallback, errorCallback) => {
  axiosClient
    .get(getURL('/barcos.json'), {
      params: { solo_nombre: 1 },
      headers: {
        Authorization: currentToken(),
      },
    })
    .then((response) => {
      const newData = response.data.map((barco) => ({
        key: barco.nombre,
        value: barco.nombre,
        text: barco.nombre,
      }));
      dataCallback(newData);
    })
    .catch((error) => {
      if (error.response) {
        errorCallback(error.response.data);
      } else {
        errorCallback(error.message);
      }
    });
};

const getVaucher = (vaucherId, dataCallback, errorCallback) => {
  axiosClient
    .get(getURL(`/vaucheres/${vaucherId}`), {
      headers: {
        Authorization: currentToken(),
      },
    })
    .then((response) => {
      const vaucherInfo = {
        nombre_barco: response.data.nombre_barco,
        items_vaucher_attributes: response.data.items_vaucher.map((item) => ({
          ...item,
          key: item.id,
        })),
        fecha: parseDate(response.data.fecha),
        is_editing: false,
        id: response.data.id,
        solicitado_por: response.data.solicitado_por,
        edited_record: 0,
        estado: response.data.estado,
        tipo_vaucher_id: response.data.tipo_vaucher.id,
        tipo_vaucher_nombre: response.data.tipo_vaucher.nombre,
        inactivo: response.data.inactivo,
        facturado: response.data.comprobante_venta_id !== null,
        numero: response.data.numero,
        firmado: response.data.firmado,
      };
      dataCallback(vaucherInfo);
    })
    .catch((error) => {
      if (error.response) {
        errorCallback(error.response.data);
      } else {
        errorCallback(error.message);
      }
    });
};

const createVaucher = (vaucherInfo, successCallback, errorCallback) => {
  axiosClient
    .post(
      getURL('/vaucheres'),
      {
        vaucher: vaucherInfo,
      },
      {
        headers: {
          Authorization: currentToken(),
        },
      }
    )
    .then((response) => {
      successCallback(response.data.id);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

const updateVaucher = (vaucherId, vaucherInfo, successCallback, errorCallback) => {
  axiosClient
    .put(
      getURL(`/vaucheres/${vaucherId}`),
      {
        vaucher: vaucherInfo,
      },
      {
        headers: {
          Authorization: currentToken(),
        },
      }
    )
    .then((response) => {
      const newData = {
        nombre_barco: response.data.nombre_barco,
        items_vaucher_attributes: response.data.items_vaucher.map((item) => ({
          ...item,
          key: item.id,
        })),
        fecha: parseDate(response.data.fecha),
        is_editing: false,
        id: response.data.id,
        solicitado_por: response.data.solicitado_por,
        edited_record: 0,
        estado: response.data.estado,
        inactivo: response.data.inactivo,
      };
      successCallback(newData);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

const firmarVaucher = (vaucherId, successCallback, errorCallback) => {
  axiosClient
    .post(
      getURL(`/vaucheres/${vaucherId}/firmar`),
      {},
      {
        headers: {
          Authorization: currentToken(),
        },
      }
    )
    .then((response) => {
      const newData = {
        nombre_barco: response.data.nombre_barco,
        items_vaucher_attributes: response.data.items_vaucher.map((item) => ({
          ...item,
          key: item.id,
        })),
        fecha: parseDate(response.data.fecha),
        is_editing: false,
        id: response.data.id,
        solicitado_por: response.data.solicitado_por,
        edited_record: 0,
        estado: response.data.estado,
        firmado: response.data.firmado,
      };
      successCallback(newData);
    })
    .catch((error) => {
      if (error.response) {
        errorCallback(error.response.data);
      } else {
        errorCallback(error.message);
      }
    });
};

const getVauchers = (perPage = 10, pageNumber = 1, filtros, successCallback, errorCallback) => {
  const url = getURL('/vaucheres.json');
  const parameters = {
    per_page: perPage,
    page: pageNumber,
    titular_id: filtros.filtroCliente,
    nombre_barco: filtros.filtroBarco,
    fecha: filtros.filtroFecha,
    firmado: filtros.filtroFirmado,
    solicitado_por: filtros.filtroSolicitadoPor,
    tipo_vaucher_id: filtros.filtroTipoVaucher,
    numero_desde: filtros.numeroDesde,
    numero_hasta: filtros.numeroHasta,
  };
  axiosClient
    .get(url, {
      params: parameters,
      headers: {
        Authorization: currentToken(),
      },
    })
    .then((data) => {
      const vaucherInfo = data.data.vauchers.map((voucher) => {
        const result = {
          ...voucher,
          items_vaucher_attributes: voucher.items_vaucher,
          fecha: parseDate(voucher.fecha),
          firmado: voucher.usuario_firma !== null,
          facturado: voucher.comprobante_venta_id !== null,
        };
        delete result.items_vaucher;
        return result;
      });
      successCallback({ vauchers: vaucherInfo, pagination: data.data.paging });
    })
    .catch((error) => {
      if (error.response) {
        errorCallback(error.response.data);
      } else {
        errorCallback(error.message);
      }
    });
};

const getServicios = (successCallback) => {
  const url = getURL('/servicios.json');
  axiosClient
    .get(url, {
      headers: {
        Authorization: currentToken(),
      },
    })
    .then((response) => {
      const opciones = response.data.map((s) => ({
        key: s.id,
        value: s.id,
        text: s.nombre,
        requiereLancha: s.requiere_lancha,
      }));
      successCallback(opciones);
    })
    .catch((error) => {
      console.log(`Error${error}`);
    });
};

const getLanchas = (successCallback) => {
  const url = getURL('/lanchas.json');
  axiosClient
    .get(url, {
      headers: {
        Authorization: currentToken(),
      },
    })
    .then((response) => {
      const opciones = response.data.map((s) => ({
        key: s.id,
        value: s.id,
        text: s.nombre,
      }));
      successCallback(opciones);
    })
    .catch((error) => {
      console.log(`Error${error}`);
    });
};

const isLoggedIn = () =>
  localStorage.getItem('evc-token') !== null && localStorage.getItem('evc-token') !== '';

const isLoginPage = () => window.location.pathname === '/login';

const mustRedirectToLogin = () => !isLoggedIn() && !isLoginPage();

const logOut = () => {
  localStorage.removeItem('evc-token');
  localStorage.removeItem('evc-login');
  localStorage.removeItem('evc-user');
};

export {
  loginUser,
  isLoggedIn,
  getServicios,
  getLanchas,
  getClientes,
  getVauchers,
  getVaucher,
  getTiposVaucher,
  updateVaucher,
  firmarVaucher,
  getBarcos,
  createVaucher,
  logOut,
  isLoginPage,
  mustRedirectToLogin,
  currentLogin,
  currentToken,
  getURL,
  EMPTY_SERVICE,
  EMPTY_VAUCHER,
};
